<template>
    <b-modal id="addCustomerAnswerModal" centered title="Adicionar Resposta de Cliente">
        <validation-observer ref="addCustomerAnswerForm">
            <form>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                        <label for="add-group-name">Campanha</label>
                        <select
                            v-model="campaign_id"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            class="form-control"
                            id="add-group-campaign"
                        >
                            <option v-if="campaigns.length === 0" :value="null">Nenhum resultado encontrado</option>
                            <option v-else :value="null" hidden>Selecione uma resposta</option>
                            <option v-for="(campaign, index) in campaigns" :value="campaign.key" :key="index">{{ campaign.text }}</option>
                        </select>
                    </validation-provider>
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                        <label for="add-group-name">Campo</label>
                        <select
                            v-model="newCustomerAnswer.name"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            class="form-control"
                            id="add-group-name"
                        >
                            <option v-if="customerQuestions.length === 0" :value="null">Nenhum resultado encontrado</option>
                            <option v-else :value="null" hidden>Selecione uma resposta</option>
                            <option v-for="(answer, index) in customerQuestions" :value="answer.name" :key="index">{{ answer.name }}</option>
                        </select>
                    </validation-provider>
                </div>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel, close }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button @click="handleAddCustomerAnswer(close)" class="btn btn-primary" :disabled="loadingAnswers">Adicionar</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import api from "@/api";


const defaultCustomerAnswer = {
    name: null,
    value: null,
    answers: []
}

export default {
    components: {
        BModal,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            newCustomerAnswer: {
                name: null,
                value: null,
                answers: [],
            },
            campaign_id: null,
            campaigns: [],
            customerQuestions: [],
            customerAnswers: [],
            loadingAnswers: false,
            loading: {
                campaigns: false,
                answers: false
            }
        }
    },
    emits: ['input'],
    mounted() {
        this.getCampaigns();
    },
    methods: {
        handleAddCustomerAnswer(closeHandler) {
            this.$refs.addCustomerAnswerForm.validate()
            .then(success => {
                if(!success) return;
                this.newCustomerAnswer.answers = this.customerAnswers;
                this.$emit('input', JSON.parse(JSON.stringify(this.newCustomerAnswer)));
                this.$refs.addCustomerAnswerForm.reset();
                this.resetAttributes();
                closeHandler();
            })
        },

        loadCustomerQuestions() {
            api.get('/all-answers', {
                params: {
                    with_campaign: true,
                    campaign_id: this.campaign_id
                }
            })
            .then(response => {
                this.customerQuestions = response.data.body.fields;
            });
        },

        getAnswers() {
            this.loadingAnswers = true;
            if (this.newCustomerAnswer.name == null) return;
            api.get('/campaign-answers/', {
                params: {
                    campaign_id: this.campaign_id,
                    question: this.newCustomerAnswer.name
                }
            })
                .then(response => {
                    this.customerAnswers = response.data.body.answers;
                })
                .finally(() => {
                    this.loadingAnswers = false;
                })
        },

        resetAttributes() {
            this.generalFields  = [];
            this.generalAnswers = [];
            this.selectedForm   = null;
            this.newCustomerAnswer = defaultCustomerAnswer;
        },

        getCampaigns() {
            this.loading.campaigns = true;
            const params = {
                all: 1, only: ['_id', 'name'], campaign_type: 'interaction'
            };

            api.get('/campaigns', {params})
                .then(res => {
                    const {body} = res.data;
                    this.campaigns = body.map(e => {
                        return {
                            text: e.name,
                            key: e._id
                        }
                    });
                })
                .finally(() => this.loading.campaigns = false)
        },
    },
    watch: {
        'newCustomerAnswer.name': {
            handler() {
                this.newCustomerAnswer.campaign_id = this.campaign_id;
                this.getAnswers();
            }
        },
        campaign_id: {
            handler() {
                this.loadCustomerQuestions();
            }
        }
    }
}
</script>
