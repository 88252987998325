<template>
	<div>
		<section>
			<div class="d-flex justify-content-between align-items-center flex-wrap">
				<h5 class="text-dark text-left font-weight-bold">Dados Gerais</h5>
				<h5 class="d-flex align-items-center m-0">
					Total de disparos:
					<span class="spinner-border spinner-border-sm text-primary m-0 ml-2" v-if="loading" role="status"></span>
					<span class="text-blue h3 font-weight-bolder m-0 ml-2" v-else>
						{{ shots || "-" }}
					</span>
				</h5>
			</div>
			<div class="row mt-3">
				<div
					v-for="(field, i) in segmentation.general_data"
					:key="`general-data-${i}`"
					class="col-12 col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center"
				>
					<validation-provider #default="{ errors }" rules="required">
						<div class="input-floating flex-grow-1">
							<input
								type="text"
								v-if="'form' in field && field.form"
								v-model="field.value"
								:class="`form-control form-input ${errors[0] && 'is-invalid'}`"
								:disabled="'form' in field"
							/>
							<multiselect
								v-else
								style="width: 18rem"
								:multiple="true"
								:options="field.answers"
								placeholder="Selecione os dados"
								:id="`general-data-field-${i}`"
								v-model="field.value"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
							>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span
										class="multiselect__single"
										v-if="values.length &amp;&amp; !isOpen"
									>
										{{ values.length }} opções selecionadas
									</span>
								</template>
							</multiselect>

							<label :for="`general-data-field-${i}`">{{ field.name }}</label>
						</div>
					</validation-provider>
					<button
						class="remove-btn ml-2"
						@click="() => segmentation.general_data.splice(i, 1)"
					>
						<font-awesome-icon icon="fa-solid fa-minus" />
					</button>
				</div>
				<div class="col-12 mt-4 d-flex align-items-center">
					<span
						v-b-modal.addGeneralDataModal
						class="d-flex align-items-center"
						role="button"
					>
						<span class="circle-icon mr-2">+</span>
						<span class="m-0 h6 font-weight-bold">Adicionar um novo campo</span>
					</span>
				</div>
			</div>
		</section>

		<section class="mt-5">
			<div class="d-flex align-items-center">
				<h5 class="text-dark text-left font-weight-bold">Respostas de Clientes</h5>
			</div>
			<div class="row mt-3">
				<div
					v-for="(field, i) in segmentation.customer_answers"
					:key="`answer-data-${i}`"
					class="col-12 col-sm-6 col-md-4 col-lg-3 my-2 d-flex align-items-center"
				>
					<validation-provider #default="{ errors }" rules="required">
						<div class="input-floating flex-grow-1">
							<multiselect
								style="width: 18rem"
								:multiple="true"
								:options="field.answers"
								placeholder="Selecione os dados"
								:id="`answer-data-field-${i}`"
								v-model="field.value"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
								class="form-control"
							>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span
										class="multiselect__single"
										v-if="values.length &amp;&amp; !isOpen"
									>
										{{ values.length }} opções selecionadas
									</span>
								</template>
							</multiselect>
							<label :for="`answer-data-field-${i}`">{{ field.name }}</label>
						</div>
					</validation-provider>
					<button
						class="remove-btn ml-2"
						@click="() => segmentation.customer_answers.splice(i, 1)"
					>
						<font-awesome-icon icon="fa-solid fa-minus" />
					</button>
				</div>
				<div class="col-12 mt-4 d-flex align-items-center">
					<span
						v-b-modal.addCustomerAnswerModal
						class="d-flex align-items-center"
						role="button"
					>
						<span class="circle-icon mr-2">+</span>
						<span class="m-0 h6 font-weight-bold">Adicionar um novo campo</span>
					</span>
				</div>
			</div>
		</section>

		<section class="mt-5">
			<div class="d-flex align-items-center mb-3">
				<h5 class="text-dark text-left font-weight-bold">
					Dados Comportamentais na Ferramenta
				</h5>
			</div>
			<div
				v-for="(field, i) in segmentation.behavioral_data"
				:key="`behavior-data-${i}`"
				class="row"
			>
				<div class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center">
					<validation-provider #default="{ errors }" class="flex-grow-1" rules="required">
						<div class="input-floating flex-grow-1">
							<select
								:id="`behavior-action-field-${i}`"
								v-model="field.action"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
							>
								<option>Recebeu</option>
								<option>Abriu</option>
								<option>Interagiu</option>
							</select>
							<label :for="`behavior-action-field-${i}`">Ação</label>
						</div>
					</validation-provider>
				</div>
				<div class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center">
					<validation-provider #default="{ errors }" class="flex-grow-1" rules="required">
						<div class="input-floating flex-grow-1">
							<select
								:id="`behavior-condition-field-${i}`"
								v-model="field.condition"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
							>
								<option>Menos de</option>
								<option>Mais de</option>
								<option>Igual a</option>
								<option>Diferente de</option>
							</select>
							<label :for="`behavior-condition-field-${i}`">Condição</label>
						</div>
					</validation-provider>
				</div>
				<div class="my-2 col-12 col-sm-6 col-lg-2 d-flex align-items-center">
					<validation-provider
						#default="{ errors }"
						class="flex-grow-1"
						rules="required|min:0"
					>
						<div class="input-floating flex-grow-1">
							<input
								:id="`behavior-quantity-field-${i}`"
								v-model="field.quantity"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
								min="0"
								type="number"
							/>
							<label :for="`behavior-quantity-field-${i}`">Qtd.</label>
						</div>
					</validation-provider>
				</div>
				<div class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center">
					<validation-provider #default="{ errors }" class="flex-grow-1" rules="required">
						<div class="input-floating flex-grow-1">
							<select
								:id="`behavior-type-field-${i}`"
								v-model="field.type"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
							>
								<option>Divulgação</option>
								<option>Interação</option>
								<option>Qualquer</option>
							</select>
							<label :for="`behavior-type-field-${i}`">Tipo de Campanha</label>
						</div>
					</validation-provider>
				</div>
				<div class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center">
					<validation-provider #default="{ errors }" class="flex-grow-1" rules="">
						<div class="flex-grow-1">
							<b-form-datepicker
								:id="`behavior-period-initial-field-${i}`"
								v-model="field.period.initial_date"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
								:date-format-options="{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric'
								}"
								placeholder="Data inicial"
								:max="max_date(field.period.final_date)"
							></b-form-datepicker>
						</div>
					</validation-provider>
				</div>
				<div class="my-2 col-12 col-sm-6 col-lg-3 d-flex align-items-center">
					<validation-provider #default="{ errors }" class="flex-grow-1" rules="">
						<div class="flex-grow-1">
							<b-form-datepicker
								:id="`behavior-period-final-field-${i}`"
								v-model="field.period.final_date"
								:class="`form-control ${errors[0] && 'is-invalid'}`"
								:date-format-options="{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric'
								}"
								placeholder="Data final"
								:max="today()"
								:min="min_date(field.period.initial_date)"
							></b-form-datepicker>
						</div>
					</validation-provider>

					<button
						class="remove-btn ml-2"
						@click="() => segmentation.behavioral_data.splice(i, 1)"
					>
						<font-awesome-icon icon="fa-solid fa-minus" />
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-4 d-flex align-items-center">
					<span class="d-flex align-items-center" role="button" @click="addBehavior">
						<span class="circle-icon mr-2">+</span>
						<span class="m-0 h6 font-weight-bold">Adicionar um novo comportamento</span>
					</span>
				</div>
			</div>
		</section>
		<add-customer-answer-modal @input="(e) => segmentation.customer_answers.push(e)" />
		<add-general-data-modal @input="(e) => segmentation.general_data.push(e)" />
	</div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import AddCustomerAnswerModal from "./components/AddCustomerAnswerModal.vue";
import AddGeneralDataModal from "./components/AddGeneralDataModal.vue";
import Multiselect from "vue-multiselect";
import { BFormDatepicker } from "bootstrap-vue";
import api from "@/api";

export default {
	components: {
		ValidationProvider,
		AddCustomerAnswerModal,
		AddGeneralDataModal,
		Multiselect,
		BFormDatepicker
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {
					general_data: [],
					customer_answers: [],
					behavioral_data: []
				};
			}
		},
		shots: {
			type: Number,
			default: 0
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		segmentation: {
			handler: function (value) {
				this.$emit("input", value);
			},
			deep: true
		}
	},
	emits: ["input"],
	data() {
		return {
			required,
			segmentation: this.$props.value
		};
	},
	methods: {
		addBehavior() {
			this.segmentation.behavioral_data.push({
				action: null,
				condition: null,
				quantity: null,
				type: null,
				period: {
					initial_date: null,
					final_date: null
				}
			});
		},
		getAnswers(data) {
			if (!data.name || !data.form_id || data.name == '')
				return;
			
			api.get("/customer-answers", {
				params: {
					search_answer: data.name,
					form_id: data.form_id
				}
			}).then((response) => {
				data.answers = response.data.body.answers;
			});
		},
		max_date(final_date) {
			if (final_date !== null) {
				return final_date;
			}
			return this.today();
		},
		min_date(initial_date) {
			if (initial_date !== null) {
				return initial_date;
			}

			return new Date(0);
		},
		today() {
			return new Date();
		}
	},
	mounted() {
		this.segmentation.general_data.forEach((field) => {
			this.getAnswers(field);
		});
	}
};
</script>

<style lang="scss" scoped>
.input-floating {
	position: relative;

	.form-control {
		padding-top: 0.475rem;
		padding-bottom: 0.475rem;
	}

	.date-input {
		padding-top: 0.275rem !important;
		max-height: 36px;
	}

	.form-control.is-invalid + label {
		color: #dc3545;
	}

	label {
		transition: 100ms;
		background: transparent;
		position: absolute;
		padding: 0 0.5rem;
		font-size: 1rem;
		line-height: 1rem;
		margin: 0 !important;
		top: 50%;
		left: 0.5rem;
		transform: translateY(-50%);
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.form-control:focus + label,
	.form-control:not(:placeholder-shown) + label {
		left: 0.3rem;
		top: -20%;
		transform: translateY(0);
		background: white;
	}
}

.form-input:disabled {
	background: white;
}
</style>
