<template>
	<b-modal id="addGeneralDataModal" centered title="Adicionar Dado Geral">
		<validation-observer ref="addGeneralDataForm">
			<form>
				<div class="form-group">
					<validation-provider #default="{ errors }" rules="required">
						<label for="form-select">Formulário</label>
						<select
							v-model="selectedForm"
							:class="`form-control ${errors.length > 0 && 'is-invalid'}`"
							class="form-control"
							id="form-select"
						>
							<option v-if="forms.data.length === 0" :value="null">
								Nenhum formulário encontrado
							</option>
							<option v-else :value="null">Selecione um dado</option>
							<option
								v-for="(form, index) in forms.data"
								:value="form._id"
								:key="index"
							>
								{{ form.name }}
							</option>
						</select>
					</validation-provider>

					<validation-provider #default="{ errors }" rules="">
						<label for="add-group-name">Campo</label>
						<select
							v-model="newGeneralData.name"
							:class="`form-control ${errors.length > 0 && 'is-invalid'}`"
							class="form-control"
							id="add-group-name"
							@change="changeCampo"
						>
							<option v-if="generalFields.length === 0" :value="null" hidden>
								Nenhum resultado encontrado
							</option>
							<option v-else :value="null" hidden>Selecione um dado</option>
							<option v-for="(answer, index) in generalFields" :key="index">
								{{ answer }}
							</option>
						</select>
					</validation-provider>
				</div>
			</form>
		</validation-observer>
		<template #modal-footer="{ cancel, close }">
			<div class="d-flex align-items-center jusitfy-content-end">
				<button @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
				<button
					@click="handleAddGeneralData(close)"
					class="btn btn-primary"
					:disabled="disabled"
				>
					Adicionar
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import api from "@/api";

const defaultGeneralData = {
	name: null,
	value: null,
	form: null,
	answers: []
};

export default {
	components: {
		BModal,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			required,
			forms: {
				data: [],
				errors: null
			},
			selectedForm: null,
			newGeneralData: {
				form_id: null,
				form: false,
				name: null,
				value: null,
				answers: []
			},
			generalFields: [],
			generalAnswers: [],
			disabled: false
		};
	},
	emits: ["input"],
	mounted() {
		this.getForms();
	},
	methods: {
		handleAddGeneralData(closeHandler) {
			this.$refs.addGeneralDataForm.validate().then((success) => {
				if (!success) return;
				this.newGeneralData.answers = this.generalAnswers;

				if (this.selectedForm !== null && this.newGeneralData.name == null) {
					this.newGeneralData.name = "Formulário";
					this.newGeneralData.value =
						this.forms.data.filter((form) => form._id === this.selectedForm)[0].name ||
						"";
					this.newGeneralData.form = true;
				}

				this.newGeneralData.form_id = this.selectedForm;

				this.$emit("input", JSON.parse(JSON.stringify(this.newGeneralData)));
				this.$refs.addGeneralDataForm.reset();
				closeHandler();
				this.resetAttributes();
			});
		},

		loadGeneralFields() {
			api.get("/forms/fields", {
				params: {
					form_id: this.selectedForm
				}
			}).then((response) => {
				this.generalFields = response.data.body;
			});
		},

		getForms() {
			this.$store
				.dispatch("form/index", {
					auth: this.$store.getters["user/getAuth"],
					with_customers: true,
					has_whatsapp: true
				})
				.then(() => {
					this.forms.data = this.$store.getters["form/get"];
				});
		},

		getAnswers() {
			if (
				!this.newGeneralData ||
				!this.newGeneralData.name ||
				!this.selectedForm ||
				this.newGeneralData.name == ""
			) {
				return;
			}

			api.get("/customer-answers", {
				params: {
					search_answer: this.newGeneralData.name,
					form_id: this.selectedForm
				}
			}).then((response) => {
				this.generalAnswers = response.data.body.answers;
				this.disabled = false;
			});
		},

		resetAttributes() {
			this.generalFields = [];
			this.generalAnswers = [];
			this.selectedForm = null;
			this.newGeneralData = { ...defaultGeneralData };
		},
		changeCampo() {
			this.disabled = true;
		}
	},
	watch: {
		selectedForm: {
			handler(value) {
				if (!value) return (this.generalFields = []);
				this.loadGeneralFields();
			}
		},
		"newGeneralData.name": {
			handler() {
				this.getAnswers();
			}
		}
	}
};
</script>
