<template>
	<section id="dashboard-new-segmentation" class="new-segmentation">
		<b-card no-body>
			<b-card-body class="p-5 d-flex flex-column">
				<h1 class="text-left text-secondary font-weight-bolder new-segmentation__title">
					{{ segmentationId ? "Editar" : "Novo" }} Modelo de Segmentação
				</h1>

				<validation-observer ref="segmentationFormValidation">
					<validation-provider #default="{ errors }" rules="required">
						<input
							v-model="name"
							:class="`my-3 form-control ${errors[0] && 'is-invalid'}`"
							placeholder="Nome"
							type="text"
						/>
					</validation-provider>

					<segmentation
						v-model="segmentation"
						:loading="loading"
						:shots="shots_count"
						v-if="loaded"
						class="mt-4"
					></segmentation>

					<div style="height: 400px" class="w-100 position-relative" v-else>
						<loading />
					</div>
				</validation-observer>

				<div class="row align-items-center justify-content-end">
					<div class="col-12 col-md-4 d-flex mt-2">
						<button
							class="btn btn-outline-primary flex-grow-1 mr-2"
							@click.prevent="goToHome"
							:disabled="loading"
						>
							Cancelar
						</button>
						<button
							class="btn flex-grow-1 btn-primary"
							@click.prevent="storeSegmentation"
							:disabled="loading"
						>
							Salvar
						</button>
					</div>
				</div>
			</b-card-body>
		</b-card>
	</section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BCardBody, BCard } from "bootstrap-vue";
import _ from "lodash";

import Segmentation from "@/views/pages/dashboard/new-segmentation/Segmentation.vue";
import api from "@/api";
import Loading from "@/views/components/Loading.vue";

export default {
	name: "NovaSegmentacao",
	components: {
		BCard,
		BCardBody,
		ValidationObserver,
		ValidationProvider,
		Segmentation,
		Loading
	},

	data() {
		return {
			name: null,
			segmentation: {
				general_data: [],
				customer_answers: [],
				behavioral_data: []
			},
			loading: true,
			loaded: false,
			saving: false,
			shots_count: 0,
			customerQuestions: [],
			customerAnswers: []
		};
	},
	mounted() {
		if (this.segmentationId) this.getSegmentation();
		else this.loaded = true;

		this.filterSegmentation();
	},
	computed: {
		segmentationId() {
			const id = this.$route.params.id;

			if (id == undefined || id == null || id == "" || id == "undefined") return undefined;

			return this.$route.params.id;
		}
	},
	methods: {
		goToHome() {
			this.$swal({
				title: "Operação cancelada",
				icon: "info",
				showConfirmButton: false,
				timer: 1000
			}).then(() => this.$router.replace({ name: "register" }));
		},

		getSegmentation() {
			this.loading = true;

			api.get(`/segmentation/show/${this.segmentationId}`)
				.then((response) => {
					const { general_data, customers_answers, behavioral_data, name } =
						response.data.body;
					this.name = name;
					this.segmentation.general_data = general_data;
					this.segmentation.customer_answers = customers_answers;
					this.segmentation.behavioral_data = behavioral_data;
				})
				.catch(() => this.$router.replace({ name: "campaigns" }))
				.finally(() => {
					this.loaded = true;
					this.loading = false;
				});
		},

		getSegmentationData() {
			const segmentation = JSON.parse(JSON.stringify(this.segmentation));
			const params = {
				general_data: segmentation.general_data,
				customers_answers: segmentation.customer_answers,
				behavioral_data: segmentation.behavioral_data
			};
			this.loading = true;
			api.post("/segmentation/search", { ...params })
				.then((response) => {
					this.shots_count = response.data.body.shots_count;
				})
				.catch((response) => {
					return this.$swal({
						title: "Ocorreu um erro",
						text: response.data.message,
						icon: "error",
						confirmButtonText: "OK",
						confirmButtonColor: "#317f98"
					});
				})
				.finally(() => (this.loading = false));
		},

		filterSegmentation: _.debounce(function () {
			this.getSegmentationData();
		}, 500),

		storeSegmentation() {
			this.$refs.segmentationFormValidation.validate().then((result) => {
				if (!result) return;

				if (this.shots_count > 15000) {
					return this.$bvToast.toast(
						"A segmentação não pode conter mais do que 15000 clientes",
						{
							title: "Segmentação",
							autoHideDelay: 2000,
							variant: "danger"
						}
					);
				}
				
				this.loading = true;
				const segmentationId = this.$route.params.id || 0;
				const method = this.$route.params.id ? "put" : "post";
				const path = this.$route.params.id ? `update/${segmentationId}` : "store";

				api[method](`segmentation/${path}`, {
					name: this.name,
					general_data: this.segmentation.general_data,
					customers_answers: this.segmentation.customer_answers,
					behavioral_data: this.segmentation.behavioral_data
				})
					.then((response) => {
						const { body, message, type } = response.data;
						this.$swal("Segmentação", message, type).then(() => {
							if (body._id) this.$router.replace({ name: "segmentation" });
						});
					})
					.finally(() => (this.loading = false));
			});
		}
	},
	watch: {
		segmentation: {
			handler: function (value) {
				let canSearch = true;

				if ("behavioral_data" in value) {
					canSearch =
						value.behavioral_data.filter(
							(behavior) =>
								behavior.action == null ||
								behavior.condition == null ||
								behavior.quantity == null
						).length < 1;
				}

				if (canSearch) {
					this.$emit("input", value);
					this.filterSegmentation();
				}
			},
			deep: true
		}
	}
};
</script>

<style scoped></style>
